import { render, staticRenderFns } from "./ClientCard.vue?vue&type=template&id=e3fe426c&scoped=true"
import script from "./ClientCard.vue?vue&type=script&lang=ts"
export * from "./ClientCard.vue?vue&type=script&lang=ts"
import style0 from "./ClientCard.vue?vue&type=style&index=0&id=e3fe426c&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3fe426c",
  null
  
)

export default component.exports